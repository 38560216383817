import CheckIcon from '@mui/icons-material/Check';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import TourIcon from '@mui/icons-material/Tour';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, matchPath } from 'react-router-dom';
import { getService } from './data/services';
import { getRoutePath } from './routes';
import UserContext from './UserContext';
import { withRouter, withRouterInnerRef } from './utils/withRouterRef';
import Confirmation from './components/feedback/Confirmation';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ProfileIcon from '@mui/icons-material/Storage';
import MenuItem from '@mui/material/MenuItem';
import { getProfileLabel } from './data/App';
import { NavigateBefore } from '@mui/icons-material';
import { ListItemButton } from '@mui/material';

const styles = theme => ({
  toolbarSpacer: {
    ...theme.mixins.toolbar,
    height: '64px'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7)
  },
  drawerPaper: {
    justifyContent: 'space-between'
  },
  ossLicenses: {
    margin: theme.spacing(2)
  },
  ossLicensesButton: {
    color: '#aaa',
    fontWeight: 500
  }
});

const drawerWidth = 250;

class SideNavItem extends React.Component {

  static propTypes = {
    route: PropTypes.object,
    routes: PropTypes.array,
    router: PropTypes.object,
    hasVerifier: PropTypes.bool,
    location: PropTypes.object,
    openVerifier: PropTypes.func,
    navBar: PropTypes.object
  };

  static contextType = UserContext;

  constructor (props) {
    super(props);

    this.state = {
      openChildren: this.getOpenChildren(props.routes, props.location.pathname)
    };

    this.toggleGroup = this.toggleGroup.bind(this);
    this.renderNavItem = this.renderNavItem.bind(this);
    this.showDefaultButtonInNavbar = this.showDefaultButtonInNavbar.bind(this);
  }

  getOpenChildren (routes, currentPath) {
    const openChildren = {};
    routes?.forEach(route => {
      openChildren[route.key] = !!currentPath.startsWith(route.to);
      if (route.children?.length > 0) {
        const openSubchildren = this.getOpenChildren(route.children, currentPath);
        if (openSubchildren) { Object.assign(openChildren, openSubchildren); }
      }
    });
    return openChildren;
  }

  componentDidMount () {
    const isOperator = this.context.user.groups.includes('Operator');
    const isSupport = this.context.user.groups.includes('Support');
    this.setState({ isOperator, isSupport });
  }

  showDefaultButtonInNavbar () {
    if (!this.props.navBar.current) {
      setTimeout(() => {
        this.showDefaultButtonInNavbar();
      }, 200);
    } else {
      this.props.navBar.current.showCloseButton(false);
      this.props.navBar.current.showBackButton(false);
    }
  }

  renderNavItem (route, hasActiveChildren, parentPath) {
    const { location } = this.props;
    const { isOperator, isSupport } = this.state;
    if (!location) {
      console.log('undefined pathname', route);
    }

    const routePath = getRoutePath(route.key) + '/*';
    const isSelected = !!(!route.children && matchPath(routePath/* parentPath + '/' + route.path */, location.pathname));

    if (route.hidden) {
      return <span key={route.key}></span>;
    }

    if (route.path.startsWith('http')) {
      return <Tooltip key={`${route.key}-tooltip`} title={<FormattedMessage id={route.text}/>} placement="right"enterDelay={250}>
            <ListItem>
              <ListItemButton key={route.key} component={Link} to="/#" onClick={(event) => {
                event.preventDefault();
                window.open(route.path, route.key);
              }}>
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={<FormattedMessage id={route.text}/>}/>
              </ListItemButton>
            </ListItem>
          </Tooltip>;
    }

    return <Tooltip key={`${route.key}-tooltip`} title={<FormattedMessage id={route.text} />} placement="right" enterDelay={250}>
      <ListItem>
        <ListItemButton key={route.key} component={route.children && route.children.length !== 0 ? null : Link} to={route.to} disabled={isSelected} className={isSelected ? 'selected' : ''}
          onClick={event => {
            if (route.children) {
              event.stopPropagation();
              this.toggleGroup(route.key);
              event.cancelBubble = true;
              return false;
            } else {
              if (isSelected) {
                event.stopPropagation();
                event.cancelBubble = true;
                return false;
              }
              if (!matchPath(route.path, this.props.location.pathname)) {
                this.showDefaultButtonInNavbar();
              }
            }
            return true;
          }}>
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText>
            <FormattedMessage id={route.text} />
            {route.roles && route.roles.includes('Operator') &&
              <span className="operatorsOnly"><LockIcon /></span>
            }
            {(isOperator || isSupport) && route.featureFlags?.length > 0 &&
              <span className="operatorsOnly"><TourIcon /></span>
            }
          </ListItemText>
        </ListItemButton>
        {route.children && <IconButton id={'openMenu' + route.key} aria-label={'openMenu' + route.key} className='openMenu' onClick={() => this.toggleGroup(route.key)} size='small'>{this.state.openChildren[route.key] ? <ExpandLess /> : <ExpandMore />}</IconButton>}
      </ListItem>
    </Tooltip>;
  }

  toggleGroup (key) {
    const openChildren = Object.assign({}, this.state.openChildren);
    openChildren[key] = !openChildren[key];
    this.setState({ openChildren });
  }

  render () {
    if (!this.props) return (<div/>);
    const { route, location } = this.props;

    // mind. 1 Abo mit Verbindungsdaten
    let anySubscriptionCompleted = false;
    if (route.key === 'verifier' && this.context.apps) {
      this.context.apps.forEach(appConfig => {
        const waitingForRegistration = !appConfig.apps || appConfig.apps.length === 0 || !!appConfig.apps.find(app => (!app.serviceName || (app.status && app.status === 'PROVISIONING')));
        anySubscriptionCompleted = anySubscriptionCompleted || !waitingForRegistration;
      });
    }
    const isExpandable = route.children && route.children.length > 0;

    if (route.key === 'verifier') {
      return <>
        {this.props.hasVerifier && anySubscriptionCompleted &&
          <ListItem><Tooltip key={`${route.key}-tooltip`} title={<FormattedMessage id={route.text}/>} placement="right"
                   enterDelay={250}>
            <ListItemButton key={route.key} component={Link} target="_blank" to="/#" onClick={(event) => {
              event.preventDefault();
              this.props.openVerifier();
            }}>
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText primary={<FormattedMessage id={route.text}/>}/>
            </ListItemButton>
          </Tooltip></ListItem>}
      </>;
    } else if (route.path.startsWith('http')) {
      return <>
          <ListItem><Tooltip key={`${route.key}-tooltip`} title={<FormattedMessage id={route.text}/>} placement="right"enterDelay={250}>
            <ListItemButton key={route.key} component={Link} to="/#" onClick={(event) => {
              event.preventDefault();
              window.open(route.path, route.key);
            }}>
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText primary={<FormattedMessage id={route.text}/>}/>
            </ListItemButton>
          </Tooltip></ListItem>
      </>;
    }

    // Highlight top level element when a child is the current route
    const hasActiveChild = isExpandable ? route.children.map(child => matchPath(child.path, location.pathname)).reduce((prev, current) => prev || current) : false;
    // console.debug('hasActiveChild', hasActiveChild);

    const navItemRoot = this.renderNavItem(route, hasActiveChild);

    const navItemChildren = isExpandable
      ? <Collapse component='li' in={this.state.openChildren[route.key]} timeout="auto" unmountOnExit>
        <List component="ul" disablePadding>
          {

            route.children
              .filter(childRoute => !childRoute.hidden)
              .map((childRoute, index) => {
                const isChildExpandable = childRoute.children && childRoute.children.length > 0;
                if (isChildExpandable) {
                  return <SideNavItem key={index}
                          routes={this.props.routes}
                          route={childRoute}
                          hasVerifier={this.props.hasVerifier}
                          openVerifier={this.openVerifierConfirmation}
                          location={this.props.location}
                          router={this.props.router}
                          navBar={this.props.navBar}
                />;
                } else {
                  const hasChildActiveChild = isChildExpandable ? childRoute.children.map(child => matchPath(child.path, location.pathname)).reduce((prev, current) => prev || current) : false;
                  return this.renderNavItem(childRoute, hasChildActiveChild, route.path);
                }
              })
          }
        </List>
      </Collapse>
      : null;

    return (
      <>
        {navItemRoot}
        {navItemChildren}
      </>
    );
  }

}

// eslint-disable-next-line no-class-assign
SideNavItem = withRouter(SideNavItem);

class SideNav extends React.Component {

  static propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    drawer: PropTypes.object,
    router: PropTypes.object,
    hasVerifier: PropTypes.bool,
    routes: PropTypes.array,
    onToggle: PropTypes.func,
    onTypeChange: PropTypes.func,
    navigate: PropTypes.func,
    intl: PropTypes.object,
    user: PropTypes.object,
    navBar: PropTypes.object
  };

  static contextType = UserContext;

  constructor (props) {
    super(props);
    this.state = {
      open: true,
      openBsPopover: false,
      disableToggle: false,
      routes: [],
      pollForServicesAndProfiles: false,
      pollForServicesAndProfilesCompleted: false,
      showOpenVerifierConfirmation: false,
      visibleVerifierProfiles: []
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.openVerifierConfirmation = this.openVerifierConfirmation.bind(this);
    this.openVerifier = this.openVerifier.bind(this);
    this.loginVerifier = this.loginVerifier.bind(this);
    this.changeOpenState = this.changeOpenState.bind(this);
    this.openBsMenu = this.openBsMenu.bind(this);
    this.handleProfileChange = this.handleProfileChange.bind(this);
    this.loadVisibleVerifierProfiles = this.loadVisibleVerifierProfiles.bind(this);
  }

  toggleDrawer () {
    if (!this.state.disableToggle) {
      this.setState({ open: !this.state.open, disableToggle: true }, () => setTimeout(() => this.setState({ disableToggle: false }), 200));
      if (this.props.onToggle) {
        this.props.onToggle(!this.state.open);
      }
    }
  }

  changeOpenState (open) {
    if (!this.state.disableToggle) {
      this.setState({ open, disableToggle: true }, () => setTimeout(() => this.setState({ disableToggle: false }), 200));
      if (this.props.onToggle) {
        this.props.onToggle(open);
      }
    }
  }

  componentDidMount () {
    const tenant = this.context.user.signInUserSession.idToken.payload.logon.toUpperCase();
    const profileName = localStorage.getItem(tenant + '.defaultVerifierProfile') || this.context?.selectedProfile?.name;
    this.setState({ profileName });
    window.addEventListener('message', (event) => {
      if (event.data === 'Listening for token') {
        console.log('Listening for token');
        this.loginVerifier(100);
      } else if (event.data === 'No token received') {
        console.log('No token received');
        this.loginVerifier(1000);
      } else if (event.data === 'Credentials received') {
        console.log('Credentials received');
        this.setState({ waitForVerifierInit: false });
      }
    }, false);
    this.loadVisibleVerifierProfiles();
  }

  componentDidUpdate (prevProps) {
    if (this.props.open !== undefined && this.state.open !== this.props.open && !this.state.disableToggle) {
      this.setState({ open: this.props.open });
    }
    if (this.props?.user !== prevProps?.user) {
      this.loadVisibleVerifierProfiles();
    }
  }

  loadVisibleVerifierProfiles () {
    const visibleVerifierProfilesUnsorted = [...new Set(this.context.user.allRoles?.filter(role => role.includes(':Verifier'))?.map(role => role.split(':')[0]))];
    const visibleVerifierProfiles = this.context.visibleApps?.filter(app => visibleVerifierProfilesUnsorted?.includes(app.profileName));
    if (visibleVerifierProfiles) {
      this.setState({
        visibleVerifierProfiles
      });
    }
  }

  handleProfileChange = (event, value) => {
    const newValue = value && value.props ? value.props.value : value;
    const appConfigs = this.context.apps;
    const newIndex = appConfigs.findIndex(config => config.profileName === newValue);
    const tenant = this.context.user.signInUserSession.idToken.payload.logon.toUpperCase();
    localStorage.setItem(tenant + '.defaultVerifierProfile', newValue);
    this.setState({
      profileName: newValue,
      profileIndex: newIndex
    });
  };

  openVerifierConfirmation () {
    if (this.state.visibleVerifierProfiles?.length > 1) {
      this.setState({ showOpenVerifierConfirmation: true });
    } else {
      this.openVerifier();
    }
  }

  openVerifier () {
    const profile = this.state.visibleVerifierProfiles?.length === 1
      ? this.state.visibleVerifierProfiles[0].profileName
      : (this.state.profileName || (this.context.selectedProfile ? this.context.selectedProfile.name : this.context.selectedProfile));
    if (profile) {
      try {
        // TODO : Das profil ist teils alt. Bei einer Neu-Anmeldung teils sogar nicht gesetzt
        const appConfig = this.context.apps.find(app => app.profileName === profile);
        let dvService = appConfig.services.find(service => service.name === 'DV');
        dvService = (dvService) || getService('DV');
        const baseUrl = dvService.appBaseUrl;
        // baseUrl = 'http://localhost';
        const tenant = this.context.user.signInUserSession.idToken.payload.logon.toUpperCase() + '_' + profile.toUpperCase();
        const verifierWindow = window.open(new URL(`/isrinformationoffice/web/index.html?tenant=${tenant}&useToken=true`, baseUrl).href, 'verifier');
        console.log('Opened verifier in extra window');
        this.setState({ verifierWindow, baseUrl, waitForVerifierInit: true });
      } catch (e) {
        console.log('profile', profile, this.state.profileName);
        console.error(e);
        // Could not open DV with given profile, show confirmation dialog
        this.setState({ showOpenVerifierConfirmation: true });
      }
    } else {
      console.log('No profile selected. Can not open DV');
    }
  }

  loginVerifier (delay) {
    if (this.state.verifierWindow && this.state.waitForVerifierInit) {
      this.context.user.getCachedDeviceKeyAndPassword();
      const accessToken = this.context.user.signInUserSession.accessToken.jwtToken;
      const idToken = this.context.user.signInUserSession.idToken.jwtToken;
      const refreshToken = this.context.user.signInUserSession.refreshToken.token;
      const deviceKey = this.context.user.deviceKey;
      const deviceGroupKey = this.context.user.deviceGroupKey;
      const randomPassword = this.context.user.randomPassword;
      const ssoAppClientId = localStorage.getItem('tenant-appClient');
      const message = JSON.stringify({ accessToken, idToken, refreshToken, deviceKey, deviceGroupKey, randomPassword, ssoAppClientId });
      console.log('VerifierWindow', !!this.state.verifierWindow);

      setTimeout(() => {
        try {
          const result = this.state.verifierWindow.postMessage(message, `${this.state.baseUrl}/isrinformationoffice/`);
          console.log(result);
        } catch (error) {
          console.error('Failed sending Message', error);
        }
      }, delay);
    }
  }

  async waitForAppConfig (waitingTime, callback) {
    this.setState({ pollForServicesAndProfiles: true });
    await this.context.loadApps();
    if (this.context.apps) {
      let doPoll = false;
      this.context.apps.forEach(appConfig => {
        const waitingForRegistration = !appConfig.apps || appConfig.apps.length === 0 || appConfig.apps.find(app => (!app.serviceName || (app.status && app.status === 'PROVISIONING')));
        doPoll = doPoll || !!waitingForRegistration;
      });
      /** Warte max 5 min */
      if (doPoll && (waitingTime === undefined || waitingTime < 300000)) {
        setTimeout(() => this.waitForAppConfig(waitingTime ? (waitingTime + 2000) : 2000, callback), 2000);
      } else if (!doPoll && waitingTime > 0) {
        this.setState({ pollForServicesAndProfilesCompleted: true });
        setTimeout(() => this.setState({ pollForServicesAndProfiles: false, pollForServicesAndProfilesCompleted: false }), 10000);
        if (callback) await callback();
        this.context.loadApps();
      } else if (!doPoll) {
        this.setState({ pollForServicesAndProfilesCompleted: true });
        setTimeout(() => this.setState({ pollForServicesAndProfiles: false, pollForServicesAndProfilesCompleted: false }), 10000);
        console.error('no app found');
      }
    }
  };

  navigateToAbout = () => {
    const { navigate } = this.props;
    navigate('/about');
  };

  openBsMenu (event) {
    this.setState({ openBsPopover: true, bsMenuAnchor: event.currentTarget });
  }

  render () {
    const { classes } = this.props;
    const hasAbo = this.context.apps.find(app => app.apps && app.apps.length > 0);
    const stateCls = this.state.open ? 'drawer_open' : 'drawer_closed';
    const drawer = <div className={stateCls}>
      <div className={classes.toolbarSpacer}>
        <div className="logoWrapper">
          <img id="companyLogo" src='/logo_bs.svg' alt=""/>
          <IconButton id='aboutBuildsimpleMenu' aria-label='About Buildsimple Menu' size='small' onClick={this.openBsMenu}>{this.state.openBsPopover ? <ExpandLess /> : <ExpandMore />}</IconButton>
        </div>

      </div>
      <Divider />
      <IconButton
        id="toggleSideNav"
        aria-label={this.props.intl?.formatMessage({ id: this.state.open ? 'sideNav.hide' : 'sideNav.show' })}
        onClick={this.toggleDrawer} className='smallToggle' size='small'>
        <NavigateBefore />
      </IconButton>
      <List>
        {this.props.routes
          .filter(route => !route.hidePerDefault && !route.hidden)
          .map((route, index) =>
          <SideNavItem key={index}
                       routes={this.props.routes}
                       route={route}
                       hasVerifier={this.props.hasVerifier}
                       openVerifier={this.openVerifierConfirmation}
                       navBar={this.props.navBar}
          />
          )}
      </List>
      {this.state.pollForServicesAndProfiles &&
        <div className="pollingStatus">
          <div className="circularProgressWrapper">
            {!this.state.pollForServicesAndProfilesCompleted &&
              <CircularProgress />
            }
            <CheckIcon className={this.state.pollForServicesAndProfilesCompleted ? 'completed show' : 'completed'}/>
          </div>
          <div className="message" id='pollServiceStatusMessage'>
            <FormattedMessage id={'service.pollForServicesAndProfiles' + (this.state.pollForServicesAndProfilesCompleted ? '.success' : '')} />
          </div>
        </div>
      }
    </div>;

    return <>

      <Popover
        id='bsPopover'
        open={this.state.openBsPopover}
        onClose={() => this.setState({ openBsPopover: false })}
        anchorEl={this.state.bsMenuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >

        <List component="nav">
          <ListItem>
            <ListItemButton
              onClick={() => { this.setState({ openBsPopover: false }); this.navigateToAbout(); }}
            >
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id='about.title' />
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>

      </Popover>

      <Confirmation
        id="openVerifierConfirmation"
        title={<FormattedMessage id="service.label.dv" />}
        open={this.state.showOpenVerifierConfirmation}
        onClose={() => this.setState({ showOpenVerifierConfirmation: false })}
        onConfirmation={this.openVerifier}
        message={<>
            {this.state.visibleVerifierProfiles && this.state.visibleVerifierProfiles.length > 1 &&
              <TextField
                select
                id="profileName"
                name="profileName"
                value={this.state.profileName}
                onChange={this.handleProfileChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ProfileIcon />
                    </InputAdornment>
                  )
                }}
                SelectProps={{
                  native: false,
                  multiple: false
                }}
                style={{ minWidth: '200px' }}
                size="small"
                variant="outlined"
                spellCheck="false"
                className="profile"
              >
                {this.state.visibleVerifierProfiles.map((appConfig, idx) => {
                  const waitingForRegistration = hasAbo && (!appConfig.apps || appConfig.apps.length === 0 || !!appConfig.apps.find(app => (!app.serviceName || (app.status && app.status === 'PROVISIONING'))));
                  if (!waitingForRegistration) {
                    return (
                      <MenuItem key={idx} value={appConfig.profileName}>{getProfileLabel(appConfig)}</MenuItem>
                    );
                  }
                  return (<></>);
                })}
              </TextField>
            }
        </>}
        okButtonText={<FormattedMessage id='open'/>}
        okButtonProps={{ variant: 'contained', endIcon: <OpenInNewIcon /> }}
      />

      <Hidden lgUp implementation="js">

        <Drawer
          variant="temporary"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper
          }}
          open={this.state.open}
          onClose={() => this.changeOpenState(false)}
          onLoad={() => {
            this.props.onTypeChange(false);
          }}
        >
          {drawer}
        </Drawer>

      </Hidden>
      <Hidden mdDown implementation="js">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open
            }, classes.drawerPaper)
          }}
          open={this.state.open}
          onLoad={() => {
            this.props.onTypeChange(true);
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

    </>;
  }

}

export default withStyles(styles)(withRouterInnerRef(SideNav));
