export function currentTimeZone () {
  let ts = '+00';
  const offset = -(new Date().getTimezoneOffset() / 60);
  if (offset <= -10) {
    ts = offset;
  } else if (offset < 0) {
    ts = '-0' + (offset * -1);
  } else if (offset > 10) {
    ts = '+' + offset;
  } else if (offset > 0) {
    ts = '+0' + offset;
  }
  ts = ts + ':00';

  return ts;
};
